import { type ThemeConfig } from "@/lib/antd";

export type TypeThemeConfigColors = {
  primary: string;
  secondary: string;
  success: string;
  processing: string;
  error: string;
  background: string;
  secondaryBackground: string;
  transparent: string;
  siderBg: string;
  primaryText: string;
  secondaryText: string;
  cardBackgroundColor: string;
  borderColor: string;
  secondaryBorderColor: string;
  textDisabled: string;
  conversationOutputBg: string;
  conversationFileViewBg: string;
  conversationInputBg: string;
  hyperLinkColor: string;
  nodeCompleted: string;
  nodeWaitingForApproval: string;
  nodeApproved: string;
  quaternaryText: string;
  chatInputBackground: string;
  primaryLight: string;
  primaryDark:string;
};
export const lightThemeConfigColors = {
  primary: "#6B22BE",
  primaryLight: "#f9f0ff",
  primaryDark: "#400086",
  secondary: "#5502B4",
  success: "#52c41a",
  processing: "#1677ff",
  error: "#C83333",
  background: "#F2F2F2",
  secondaryBackground: "#ffffff",
  transparent: "transparent",
  siderBg: "#FAFAFA",
  primaryText: "#080808",
  secondaryText: "#393939",
  quaternaryText: "rgba(0, 0, 0, 0.25)",
  cardBackgroundColor: "#FAFAFA",
  borderColor: "#d9d9d9",
  secondaryBorderColor: "#f0f0f0",
  textDisabled: "#CCCCCC",
  conversationOutputBg: "#0416172a",
  conversationFileViewBg: "#0A1B1C",
  conversationInputBg: "#FAFAFA",
  hyperLinkColor: "#9B59B6",
  nodeCompleted: "#DDF3E6",
  nodeWaitingForApproval: "#FFF4B5",
  nodeApproved: "#C4D7FF",
  bgError: "#fff2f0",
  borderError: "#ffe8e6",
  colorInfo: "#1677ff",
  colorInfoBg: "#e6f4ff",
  colorInfoBorder: "#91caff",
  colorSuccessBg: "#f6ffed",
  colorSuccessBorder: "#b7eb8f",
  colorWarning: "#faad14",
  colorWarningBg: "#fffbe6",
  colorWarningBorder: "#ffe58f",
  menuItemHoverBG: "#d9f2f2",
  menuItemSelectedBG: "#b2e0e0",
  chatInputBackground: "#F2F2F2"
};

export const darkThemeConfigColors = {
  primary: "#B583FF",
  primaryLight: "#362046",
  primaryDark: "#6F00C6",
  secondary: "#9D63D4",
  success: "#4CAF50",
  processing: "#62B6FF",
  error: "#FF6B6B",

  background: "#121212",
  secondaryBackground: "#0D0D0D",
  transparent: "transparent",
  siderBg: "#080808",
  primaryText: "#FFFFFF",
  secondaryText: "#B3B3B3",
  quaternaryText: "#393939",
  cardBackgroundColor: "#1E1E1E",
  borderColor: "#4D4D4D",
  secondaryBorderColor: "#3A3A3A",
  textDisabled: "#666666",
  conversationOutputBg: "#0A1B1C",
  conversationFileViewBg: "#0A1B1C",
  conversationInputBg: "#1E1E1E",
  hyperLinkColor: "#9B59B6",
  nodeCompleted: "#2A3B2F",
  nodeWaitingForApproval: "#554C2A",
  nodeApproved: "#2A3B55",
  bgError: "#4d3836",
  borderError: "#3d2e2d",
  colorInfo: "#5ecaff",
  colorInfoBg: "#002b48",
  colorInfoBorder: "#17406d",
  colorSuccess: "#52c41a",
  colorSuccessBg: "#132a13",
  colorSuccessBorder: "#1c561e",
  colorWarning: "#faad14",
  colorWarningBg: "#402c00",
  colorWarningBorder: "#614700",
  menuItemHoverBG: "#041617",
  menuItemSelectedBG: "#0b3232",
  chatInputBackground: "#1E1E1E"
};

export const themeConfigColors = {
  primary: "#6B22BE",
  secondary: "#5502B4",
  success: "#52c41a",
  processing: "#1677ff",
  error: "#C83333",
  background: "#F2F2F2",
  transparent: "transparent",
  siderBg: "#080808",
  primaryText: "#080808",
  secondaryText: "#393939",
};
// const themeConfig: ThemeConfig = {
//   token: {
//     fontSize: 16,
//     colorPrimary: themeConfigColors.primary,
//     colorError: themeConfigColors.error,
//     colorText: themeConfigColors.primaryText,
//     colorTextSecondary: themeConfigColors.secondaryText,
//     colorSuccess: themeConfigColors.success,
//     fontFamily: "Inter Tight, sans-serif",
//     controlHeight: 41,
//   },
//   components: {
//     Tabs: {
//       itemActiveColor: "#6B22BE",
//       verticalItemMargin: "0",
//       cardPadding: "8px 16px"
//     },
//     Form: {
//       verticalLabelPadding: "0 0 4px",
//       controlHeight: 41,
//     },
//     Input: {
//       // colorBgContainer: themeConfigColors.background,
//       // colorText: '#FCFCFC',
//       // colorTextPlaceholder: '#808080',
//       // colorBorder: '#333333',
//       // hoverBorderColor:themeConfigColors.background,
//       // activeBorderColor:themeConfigColors.background,
//       // activeShadow:"0 0 3px "+themeConfigColors.background,
//       borderRadius: 8,
//       colorError: themeConfigColors.error,
//       colorErrorBorderHover: themeConfigColors.error,
//     },
//     Card: {
//       colorTextHeading: themeConfigColors.primaryText,
//       colorBgContainer: themeConfigColors.background,

//     },
//     Typography: {
//       colorText: themeConfigColors.primaryText,
//       colorTextSecondary: themeConfigColors.secondaryText,
//     },
//     Layout: { siderBg: themeConfigColors.siderBg },
//     Checkbox: {
//       colorBorder: "#3c3c3c",
//       colorBgContainer: themeConfigColors.transparent,
//       lineWidth: 2,
//       paddingXS: 15,
//       controlInteractiveSize: 20,
//     },
//     Button: {
//       // colorBgContainer: themeConfigColors.primary,
//       borderRadius: 8,
//       colorBorder: themeConfigColors.primary,
//       colorText: themeConfigColors.primary,
//       colorPrimaryText: "white",
//       // colorBgContainerDisabled: "grey",
//       // colorTextDisabled: "white",
//       // boxShadow:"4px 4px 8px 0px #AA81DA",
//       padding: 12,
//       paddingInline: "24px",
//       paddingBlock: "12px",
//       controlHeight: 48,
//       defaultBorderColor: "#ffffff",
//       defaultHoverBg:
//         "linear-gradient(154.92deg, #F5F5F5 15.82%, #EDEAF0 83.93%)",
//       defaultHoverBorderColor: "#ffffff",
//       defaultShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
//       defaultBg: "linear-gradient(154.92deg, #F5F5F5 15.82%, #EDEAF0 83.93%)",
//       // padding:"12px 24px 12px 24px"
//     },
//     // box-shadow: 4px 4px 8px 0px var(--ColorSecondarySecondary50);
//     Divider: {
//       // colorTextHeading: "#595959",
//       fontSize: 16,
//       // colorSplit: "#595959",
//       colorBorder: "#CECECE",
//       colorBorderBg: "#CECECE",
//       margin: 24,
//     },
//     Menu: {
//       itemBorderRadius: 8,
//       padding: 16,
//       itemColor: "#FFFFFF",
//       colorText: "#FFFFFF",
//       // itemHoverBg: "#14123d",
//       darkItemHoverBg: "#041617",
//       darkItemSelectedBg: "#0b3232",
//     },

//     // Table: {
//     //   headerBg: '#edeef0'
//     // }
//   },
// };

// export  themeConfig;
