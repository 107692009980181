import React from "react";
import {  Typography } from "antd";

const { Title, Text } = Typography;

const MobileOnboarding = () => {
  return (
    <div className="flex h-screen flex-col items-center  bg-gray-100  overflow-hidden">
        <div className="px-4 w-full max-w-md flex-1 flex flex-col justify-center">

        <Title level={2} className="text-center mt-4 mb-2">
          We are currently available only on desktop.
        </Title>

        <Text className="block text-center text-gray-600 mb-4">
          Please join in from a desktop browser to access Kiwi.
        </Text>
        </div>
      <div className="w-full max-w-md ">

        <video
          autoPlay
          loop
          muted
          style={{
            height: "auto",
            width: "100vw",
            objectFit: "cover",
          }}
          controls={false}
          playsInline
        >
          <source src="/videos/kiwi_video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default MobileOnboarding;
