"use client";

import { Button, Checkbox, Form, Input } from "@/lib/antd";
import Image from "next/image";
import AuthLayout from "../auth-layout";
import Link from "next/link"; // Make sure to import Link
import { useEffect } from "react";
import { themeConfigColors } from "@/theme/theme-config";
import { Typography } from "@/lib/antd";
import { useTheme } from "@/contexts/themeContext/themeContext";

type Props = {
  initialValues: object;
  isLoading: boolean;
  onSubmit: (values: any) => void;
};

export default function Login(props: Props) {
  const { themeConfigColors } = useTheme();
  const { initialValues } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  console.log(location);

  return (
    <AuthLayout>
      <div className="flex justify-center items-center h-screen w-full">
        <div>
          <div
            className="text-[2rem] font-medium mb-4"
            style={{
              color: themeConfigColors.primaryText,
            }}
          >
            Login
          </div>
          <div
            className="text-xl font-semibold mb-8"
            style={{
              color: themeConfigColors.secondaryText,
            }}
          >
            Welcome! Please enter your details.
          </div>
          <Form
            form={form}
            className="w-auto lg:w-[30rem]"
            name="login"
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
            initialValues={initialValues}
            onFinish={props.onSubmit}
          >
            <Form.Item
              label={<div className="text-grey-400 text-sm">Email Address</div>}
              name="email"
              className="mb-4"
              rules={[
                {
                  required: true,
                  message: "Please input your email address!",
                },
                {
                  type: "email",
                  message: "Please input a valid email address!",
                },
              ]}
            >
              <Input
                className="h-12"
                prefix={
                  <Image
                    src="/message.svg"
                    alt="message"
                    width={20}
                    height={18}
                    className="mr-2"
                    priority
                  />
                }
                placeholder="Enter email"
              />
            </Form.Item>
            <Form.Item
              label={<div className="text-grey-400 text-sm">Password</div>}
              name="password"
              className="mb-2"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                className="h-12"
                prefix={
                  <Image
                    src="/password.svg"
                    alt="password"
                    width={20}
                    height={20}
                    className="mr-2"
                    priority
                  />
                }
                iconRender={(visible) =>
                  visible ? (
                    <Image
                      src="/show.svg"
                      alt="show"
                      width={20}
                      height={20}
                      priority
                    />
                  ) : (
                    <Image
                      src="/hide.svg"
                      alt="hide"
                      width={20}
                      height={20}
                      priority
                    />
                  )
                }
                placeholder="Enter password"
              />
            </Form.Item>
            <Form.Item className="mb-4">
              <div className="flex justify-between items-center">
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox className="text-grey-400 text-sm">
                    Remember me
                  </Checkbox>
                </Form.Item>
                <Link
                  href="/forgot_password"
                  className="text-sm"
                  style={{ color: themeConfigColors.primary }}
                >
                  Forgot password?
                </Link>
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                loading={props.isLoading}
                type="primary"
                htmlType="submit"
                className="w-full lg:w-[30rem] h-14 font-semibold bg-gradient-to-b from-[#00C2FF] from-0% via-[#3CB88B] via-100% to-[#32EDF0] to-100%"
              >
                Login
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <div className='flex'>
                <div className='text-grey-300 text-lg font-semibold'>Not registered yet?</div>
                <Link href="/signup" className='text-lg font-semibold text-teal ml-2' >Create an Account</Link>
              </div>
            </Form.Item> */}
          </Form>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 20,
            gap: "10px",
            display: "flex",
            flexDirection: "row",
          }}
          className="justify-between sm:justify-center sm:gap-[20px] text-xs sm:text-sm lg:text-base"
        >
          <Link
            href={`${
              location.host == "app.myautobots.com"
                ? "https://www.myautobots.com"
                : "https://www.meetkiwi.ai"
            }/privacy-policy`}
            target="_blank"
          >
            {" "}
            <Typography.Text className="hover:text-[#6B22BE] text-xs sm:text-sm lg:text-base">
              Privacy Policy
            </Typography.Text>
          </Link>
          <Link
            href={`${
              location.host == "app.myautobots.com"
                ? "https://www.myautobots.com"
                : "https://www.meetkiwi.ai"
            }/terms-of-service`}
            target="_blank"
          >
            {" "}
            <Typography.Text className="hover:text-[#6B22BE] text-xs sm:text-sm lg:text-base">
              Terms of Service
            </Typography.Text>
          </Link>
          <Typography.Text className="text-xs sm:text-sm lg:text-base">
            &copy; MeetKiwi Inc. 2024
          </Typography.Text>
        </div>
      </div>
    </AuthLayout>
  );
}
